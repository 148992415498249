import React from 'react';

const ToggleIconClose = () => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 66.5 44.3"
            style={{ enableBackground: "new 0 0 66.5 44.3", width: '30px' }}
        >
            <path fill='#FFFFFF' d="M1.9,3.1c20.8,0,41.5,0,62.7,0c0,2.6,0,4.9,0,7.8c-20.9,0-41.8,0-62.7,0C1.9,8.3,1.9,5.7,1.9,3.1z"/>
            <path fill='#FFFFFF' d="M1.9,18.7c20.8,0,41.5,0,62.7,0c0,2.6,0,4.9,0,7.8c-20.9,0-41.8,0-62.7,0C1.9,23.9,1.9,21.3,1.9,18.7z"/>
            <path fill='#FFFFFF' d="M1.9,34.3c20.8,0,41.5,0,63,0c0,2.8,0,5.3,0,7.8c-21,0-42,0-63,0C1.9,39.5,1.9,36.9,1.9,34.3z"/>
        </svg>
    );
};

export default ToggleIconClose;
