import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import isologo from "../images/logo_la_celia.svg";
import SimpleLocalize from "../components/SimpleLocalize";
import { FormattedMessage } from "react-intl";
import { BrowserView, MobileView} from 'react-device-detect';
import ToggleIconClose from "./ToggleIconClose";
import ToggleIconOpen from "./ToggleIconOpen";

const Header = (props) => {
  const { siteTitle } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para manejar la clase 'show'
  const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
	  if (typeof window !== "undefined") {
		const handleResize = () => {
		  setIsMobile(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
		  window.removeEventListener("resize", handleResize);
		};
	  }
	}, []);

	const isDesktop = !isMobile;
  let languagePath;
  if (props.pageContext && props.pageContext.language !== props.pageContext.defaultLanguage) {
    languagePath = "/" + props.pageContext.language + "/"
  } else {
    languagePath = '/'
  }

  const switchIdioma = () => {
    localStorage.setItem('navegacionIdiomas', true)
    if (typeof window !== 'undefined') {
      if (languagePath === '/') {
        window.location.href = '/en' + window.location.pathname
      } else {
        window.location.href = window.location.pathname.replace('/en/', '/')
      }
    }
  }

  useEffect(() => {
    if (!localStorage.getItem('navegacionIdiomas')) {
      const language = (navigator.language || navigator.userLanguage).substr(0, 2);
      var str = window.location.pathname.split("/en")[1];
      if (language === 'en' && window.location.href.search('/en') === -1) {
        str ? window.location.href = '/en' + str : window.location.href = '/en'
      } else if (language === 'es' && window.location.href.search('/en') !== -1) {
        window.location.href = str;
      }
    }
  }, [])

  // Función para manejar el click en el ícono de la barra de navegación
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <SimpleLocalize {...props}>
      <header id="head">
        <nav className="navbar navbar-expand-lg fixed-top">
          <div className="container">
            <div className="col-md-3">
              <h1 className="header-logo-wrapper">
                <Link
                  className="navbar-brand"
                  to={languagePath}
                  style={{
                    color: `white`,
                    textDecoration: `none`,
                  }}
                >
                  <img src={isologo} className="img-fluid" alt="Logo" />
                </Link>
              </h1>
            </div>

			<div className="col-md-9 navbar-dark">
				{isMobile && <div className="language-selector-mobile">
					<button className="btn btn-link ps-0 text-white" style={{textDecoration: 'none'}} onClick={switchIdioma}>
						{languagePath === '/en/' ? 'ES' : 'EN'}
					</button>

			  <button
                className="btn btn-link"
                type="button"
                onClick={toggleMenu}
                aria-controls="navbarSupportedContent"
                aria-expanded={isMenuOpen}
                aria-label="Toggle navigation"
				style={{width:'56px'}}
              >
                {!isMenuOpen && <ToggleIconClose />}
				{isMenuOpen && <ToggleIconOpen />}
              </button>
			  </div>}
              <div
                className={`collapse navbar-collapse justify-content-end ${isMenuOpen ? 'show' : ''}`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
					{isMobile &&
            <div>
              <li className="nav-item">
                <Link to={`/`} className="nav-link" activeClassName="active">
                <FormattedMessage id="inicio" defaultMessage="INICIO" />
                </Link>
              </li>
            </div>}
					<li className="nav-item">
						<Link to={`${languagePath}clima#head`} className="nav-link" activeClassName="active">
						<FormattedMessage id="clima" defaultMessage="clima" />
						</Link>
					</li>
					<li className="nav-item">
						<Link to={`${languagePath}suelo#head`} className="nav-link" activeClassName="active">
						<FormattedMessage id="suelo" defaultMessage="suelo" />
						</Link>
					</li>
					<li className="nav-item">
						<Link to={`${languagePath}poligonos#head`} className="nav-link" activeClassName="active">
						<FormattedMessage id="poligonos" defaultMessage="poligonos" />
						</Link>
					</li>
					<li className="nav-item">
						<Link to={`${languagePath}vinos#head`} className="nav-link" activeClassName="active">
						<FormattedMessage id="vinos" defaultMessage="vinos" />
						</Link>
					</li>
                </ul>
				{isDesktop &&
                <div>
                  <div className="language-selector">
                  <button className="btn btn-link ps-0" onClick={switchIdioma}>
                    {languagePath === '/en/' ? 'ES' : 'EN'}
                  </button>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </SimpleLocalize>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
