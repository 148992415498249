import React from "react"

const ToggleIconOpen = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 42.6 42.9"
      style={{enableBackground:"new 0 0 42.6 42.9", width: '20px'}}
    >
      <path
        fill="#FFFFFF"
        d="M28.9,22.1l11.5-11.5c1.2-1.2,1.2-3.1,0-4.2L37.1,3c-1.2-1.2-3.1-1.2-4.2,0L21.4,14.5L9.6,2.7
	c-1.2-1.2-3.1-1.2-4.2,0L2,6.1c-1.2,1.2-1.2,3.1,0,4.2l11.8,11.8L2,33.9c-1.2,1.2-1.2,3.1,0,4.2l3.3,3.3c1.2,1.2,3.1,1.2,4.2,0
	l11.8-11.8l11.5,11.5c1.2,1.2,3.1,1.2,4.2,0l3.3-3.3c1.2-1.2,1.2-3.1,0-4.2L28.9,22.1z"
      />
    </svg>
  )
}

export default ToggleIconOpen
